import React, { FC } from 'react'
import AddCircle from '@mui/icons-material/AddCircle'
import Assignment from '@mui/icons-material/Assignment'
import { Avatar, Box, CardActionArea, Stack, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'

const IconLinkBox: FC<{
  position: 'left' | 'right'
}> = ({ position }) => {
  const content = useEpiContent<Content>()
  const Icons = {
    left: Assignment,
    right: AddCircle,
  }
  const IconComponent = Icons[position]
  const positionedContent = {
    text: content?.[`${position}LinkText`],
    url: content?.[`${position}LinkUrl`],
  }
  return (
    <Box flexBasis={{ sx: '100%', sm: '50%' }}>
      <CardActionArea
        href={positionedContent.url}
        data-testid={`${position}IconLinkBox`}
      >
        <Box p={3} borderRadius={2} bgcolor="surface.blue">
          <Stack direction="row" columnGap={2}>
            <Avatar sx={{ bgcolor: '#fff' }} variant="circular">
              <IconComponent color="primary" />
            </Avatar>
            <Typography sx={{ color: 'primary.main' }} variant="body1" mt={1}>
              {positionedContent.text}
            </Typography>
          </Stack>
        </Box>
      </CardActionArea>
    </Box>
  )
}

export default IconLinkBox
