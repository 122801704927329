import React from 'react'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { useEpiContent, useUser } from '@trr/app-shell-data'
import { Content } from 'types'

import { IconLinkBox } from './components'

const Assignments = () => {
  const content = useEpiContent<Content>()
  const user = useUser()
  const kundProperties = user?.kundProperties
  return (
    <Card data-testid="assignments">
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom>
          {content?.heading}
        </Typography>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          component="p"
          mb={2}
        >
          {kundProperties?.foretag.name}
        </Typography>
        <Stack direction={{ sx: 'column', sm: 'row' }} columnGap={1} rowGap={1}>
          <IconLinkBox position="left" />
          <IconLinkBox position="right" />
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Assignments
